<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div class="page__content">
            <h1 class="page__title">Minhas vendas</h1>
            <div class="tabs__flex">
              <div class="tabs__nav">
                <a
                  class="tabs__anchor"
                  :class="{ current: filter === '' }"
                  @click="filter = ''"
                  href="#"
                  title="Extrato"
                >
                  Vendas
                </a>

              </div>
              <div class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
                <div class="tabs__filters--group" style="padding: 10px;">
                  <a @click.prevent="downloadItem()" type="button" v-if="extracts.length > 0" style="cursor: pointer; font-size: 25px; color: green;" title="Imprimir certificado">
                    <i class="fa fa-print"></i>
                  </a>
                  <button v-else style="font-size: 25px;" title="Imprimir certificado">
                    <i class="fa fa-print"></i>
                  </button>
                </div>
              </div>
            </div>

            <div v-if="extracts.length > 0" class="tab__body current">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 200px;">Dia</th>
                    <th style="width: 500px; text-align: left;">Material</th>
                    <th style="width: 200px; text-align: center;">R$</th>
                  </tr>
                </thead>
              </table>
              <table class="table">
                <tbody style="display: table-caption; height: 500px; overflow: auto; border: 1px solid #F2F2F2;">
                  <tr style="border: none;" v-for="collect in extracts" :key="collect.id">
                    <td style="width: 293px;" data-label="Dia">
                      <span
                        >{{ collect.created_at | date }}
                        {{ collect.month }}</span
                      >
                    </td>

                    <td style="width: 500px; text-align: left;" data-label="Lançamento">
                      <span>{{ collect.receiver }}</span>
                      <small>{{ collect.local }}</small>
                    </td>

                    <td style="text-align: right; width: 430px;"
                      data-label="R$"
                      :class="collect.type === 'SAIDA' ? 'red' : 'green'"
                    >
                      <span>{{
                        `${collect.type === 'SAIDA' ? '-' : ''}${
                          collect.value
                        }` | money
                      }}
                      </span>
                      <br>
                      <small style="color: #787a85;">{{collect.payment}}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <loading v-else-if="loading"></loading>
            <div v-else>Sem dados para mostrar</div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

const defaultForm = {
  id: '',
  type: 'PF',
  city_id: null,
  province_id: null,
  name: '',
  email: '',
  phone: '',
  company_id: 1
}
export default {
  props: {
    current: Boolean
  },
  components: {
    Loading: () => import('../../components/loading.vue')
  },
  data: () => ({
    form: { ...defaultForm },
    error: null,
    load: true,
    modal: false,
    editing: {},
    msg: null,
    collects: [],
    province_id: '',
    collectors: [],
    collectorId: '',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    filter: '',
    loading: true,
    startDate: '',
    endDate: ''
  }),
  computed: {
    ...mapGetters('user', ['user']),
    collectorName () {
      const [collector] = this.collectors.filter(i => i.id === this.collectorId)
      return collector ? collector.name : '-'
    },
    monthYear () {
      const date = new Date(Date.UTC(this.year, this.month))
      const formated = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'full'
      }).format(date)
      const [year, month] = formated.split(' de ').reverse()
      return `${month} de ${year}`
    },
    extracts () {
      if (this.filter === 'entradas') return this.collects.entradas
      if (this.filter === 'saidas') return this.collects.saidas
      if (this.filter === 'futuros') return this.collects.futuros
      return this.collects.extrato || []
    }
  },
  methods: {
    ...mapActions('collector', { fetchCollectors: 'fetch' }),
    ...mapActions('client', ['fetch']),

    openEditModal (item = null) {
      this.editing = {}
      if (!item) return
      const itemToEdit = { ...item }
      this.editing = itemToEdit
      this.modal = true
    },
    async deleteClient (item = null) {
      const url = 'clients/destroy'
      this.$http.delete(url, { body: { id: item } }).then(response => {
        this.msg = response.msg
      })
    },
    openModal (item = null) {
      this.selectedEntity = item
      this.modal = true
    },
    changeDate (to) {
      let { month, year } = this
      if (to === 'next') month++
      else if (to === 'prev') month--
      if (month === 0) {
        month = 12
        year--
      } else if (month === 13) {
        month = 1
        year++
      }
      this.month = month
      this.year = year
    },
    async getHits () {
      this.loading = true
      const { id } = this.user
      let url = `financial/financialReport?id=${id}`
      const { startDate, endDate } = this

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`
      }

      const { data } = await this.$http.get(url)
      this.collects = data.data
      this.loading = false
    },
    downloadItem () {
      const { startDate, endDate, filter } = this
      const url = `extract/report?filter=${filter}&start_date=${startDate}&end_date=${endDate}`

      this.$http.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'extrato'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    }
  },
  mounted () {
    this.getHits()
  },
  filters: {
    money (val) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(parseFloat(val))
    },
    date (val) {
      let date = new Date(val).getDate()
      if (date < 10) date = `0${date}`
      return date
    }
  },
  watch: {
    collectorId (val, old) {
      if (val === old) return
      this.getHits()
    },
    month (val, old) {
      if (val === old) return
      this.getHits()
    },
    startDate (val) {
      if (val && this.endDate) this.getHits()
    },
    endDate (val) {
      if (val && this.startDate) this.getHits()
    }
  }
}
</script>

<style>
.table tbody tr td:nth-child(1) {
  font-size: 20px;
  font-weight: 500;
  width: 20px;
  height: auto;
}

.table thead th:nth-child(2) {
  width: 100%;
  height: auto;
}

.table thead th:nth-child(3) {
  text-align: right;
}

.table tbody tr td:nth-child(1) span {
  background-color: #fff;
}

.table tbody tr td:nth-child(2) span {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.table tbody tr td:nth-child(2) small {
  font-size: 12px;
  color: #b0b2b4;
}

.table tbody tr td:nth-child(3) {
  text-align: right;
}

.table tbody tr td:nth-child(3).red {
  color: #d34444;
}

.table tbody tr td:nth-child(3).green {
  color: #05b446;
}

.table tbody tr:not(:last-child) td:nth-of-type(1):after {
  content: '';
  left: 36px;
  position: absolute;
  width: -webkit-calc(105%);
  height: -webkit-calc(105%);
  size: -webkit-calc(105%);
  width: 2px;
  height: calc(105%);
  background-color: #e8e8e8;
  z-index: -1;
}

th span {
  font-size: 9pt;
}
</style>
